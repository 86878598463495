.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.my-field .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px !important;
}

.address-popover .MuiPaper-root.MuiPopover-paper {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
}

.MuiPickersCalendarHeader-root svg {
  fill: black !important;
}

.markholidayMaindiv .CalendarMain .rbc-month-row .rbc-date-cell {
  text-align: left !important;
  /* Align text to the right */
}

.markholidayMaindiv .fc-header-toolbar.fc-toolbar {
  display: none;
  /* Hide the element */
}

.markholidayMaindiv .fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000de;
}

.markholidayMaindiv .fc-daygrid-day-top {
  display: inline-block !important;
}

.markholidayMaindiv .fc-scrollgrid-sync-inner {
  display: flex !important;
}

.markholidayMaindiv .fc-daygrid-day-top {
  flex-direction: row !important;
  padding: 5px;
  height: 35px;
}

.markholidayMaindiv .fc-daygrid-day-number {
  padding: 0 !important;
}

.markholidayMaindiv
  .MuiPickersArrowSwitcher-root.css-9reuh9-MuiPickersArrowSwitcher-root {
  display: none;
}

.markholidayMaindiv
  .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer.css-15v8kdh-MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer
  > div
  > div {
  display: none;
}

.markholidayMaindiv
  .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer.css-15v8kdh-MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer
  .MuiYearCalendar-root.css-1lkpf4l-MuiYearCalendar-root {
  display: grid;
  grid-template-columns: auto auto auto;
}

.markholidayMaindiv
  .MuiPickersCalendarHeader-root.css-nk89i7-MuiPickersCalendarHeader-root {
  margin: 0;
  padding: 0;
}

.markholidayMaindiv
  .css-15v8kdh-MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer {
  position: absolute !important;
  z-index: 95;
  background: white;
  top: 40px;
}

.markholidayMaindiv .MuiDateCalendar-root.css-cwhad8-MuiDateCalendar-root {
  width: 100% !important;
}

.markholidayMaindiv .selected_date_cell .fc-daygrid-day-number1 {
  background-color: #227dc3;
  color: white;
  line-height: 20px;
  padding: 3px;
}

.markholidayMaindiv .fc-view-harness.fc-view-harness-active {
  height: 700px !important;
}

.markholidayMaindiv .fc-col-header {
  width: 100% !important;
}

.markholidayMaindiv .fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.markholidayMaindiv .fc-scrollgrid-sync-table {
  width: 100% !important;
}

.markholidayMaindiv .fc-scrollgrid-sync-table {
  height: auto !important;
  width: 100% !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.fc-daygrid-day-number1 {
  cursor: pointer !important;
}
.App-link {
  color: #61dafb;
}
.fc-scrollgrid-sync-inner{
  cursor: pointer !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color: #737387 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
