.ReferEarnMainPaper {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReferTextMain {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #0a1f41;
}

.ReferTextSub {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  color: #0a1f41;
}

.ReferEarnCopButton {
  display: flex;
  border: 2px dashed;
  padding: 15px 30px;
  align-items: center;
  column-gap: 10px;
  border-radius: 5px;
}

.ReferEarnMainButton {
  text-transform: capitalize !important;
  padding: 10px 35px;
  font-size: 14px !important;
  font-weight: 600;
}

.AddNewSubsMenuItem {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  color: #227dc3 !important;
}

.AddNewSubsMenuItem svg {
  width: 18px;
}

.AddNewSubsMenuItem path {
  fill: #227dc3 !important;
}

.AvatarSection {
  display: flex;
  column-gap: 15px;
  padding: 15px 0;
  border-top: 1px solid #eef1fd;
  position: relative;
}

.closebtn {
  display: flex !important;
  padding: 10px 0 5px !important;
  justify-content: flex-end !important;
  width: 100% !important;
}

.closebtn svg {
  color: #000;
}

.ModalHeaderSection {
  margin-top: 10px;
  background: #eff1fd;
  padding: 20px;
  border-radius: 10px;
}

.ModalHEaderMainText {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #0a1f41;
}

.ModalHEaderSubText {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #6b778c;
}

.ModalHeaderStatusText {
  position: absolute;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  color: #6b778c;
  right: 10px;
  top: 15px;
}

.snackBar {
  top: -410px !important;
  /* right: 12px !important; */
  left: 76% !important;
}

.snackBar svg {
  color: #1e4620 !important;
}

.NoReferalText1 {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}

.referal_pointsApplyButton {
  text-transform: none !important;
  height: 25px;
  padding: 0 !important;
  margin-top: 10px !important;
}

.refermodalSubmitBtn {
  text-transform: none !important;
  width: 100%;
  position: absolute;
  bottom: 10px;
}
