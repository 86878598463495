.reportBtnsContainer {
  display: grid;
  grid-template-columns: 20% 20%;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  margin-top: 20px;
  min-height: 80px;
}

.viewReportBtn {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21.6px !important;
  text-align: center;
  text-transform: none !important;
  min-height: 40px !important;
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.7) !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.datePickerInput > div {
  height: 40px !important;
  background: #fff;
}

.downloadBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.downloadFlexBtn {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  column-gap: 15px;
}

.AccordiansContainer {
  display: grid;
  row-gap: 10px;
}

.AccordiansContainer svg {
  color: #000000 !important;
}

.TextNumberCardContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.TextNumberCard {
  display: flex;
  height: 60px;
  width: 24%;
  justify-content: space-between;
  border: 1.4px solid #ececec;
  box-shadow: 0px 2px 12px 0px #136fb20d;
  border-radius: 5px;
  padding: 17px 16px;
}

.textCardLable {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21.79px !important;
  text-align: left;
  display: flex;
  align-items: center;
}

.completedcolor svg,
.pendingcolor svg,
.rejectedcolor svg {
  margin-right: 5px !important;
}

.completedcolor svg {
  color: #240051 !important;
}

.pendingcolor svg {
  color: #fbc23f !important;
}

.rejectedcolor svg {
  color: #c52a38 !important;
}

.pieChartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  column-gap: 20px;
}
.TaskCard{
  width: 100% !important;
  padding: 12px !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;
}
.bottom_box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TaskContainer {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-around;
  row-gap: 20px;
  margin-top: 20px;
}
.Reports_fields {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 25px !important;
}