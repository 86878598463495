.indexpageMainBtn {
  display: flex;
  justify-content: flex-end;
  /* margin: 0 15px 20px; */
  margin-bottom: 20px;
}

.indexpageMainBtn button svg {
  width: 18px;
  margin-right: 8px;
}

.CreateTeamindex {
  height: auto;
  width: 99%;
  background-color: white !important;
  margin-top: 10px;
  padding: 20px;
}

.ImageDeleteBtn svg {
  width: 17px;
  margin-right: 8px !important;
}

.imagePagemaindiv {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.CreateTeamimage {
  height: auto;
  width: auto;
  margin-top: 25px;
}

.form_lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}

.AudioRecorderHeading {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #227dc3;
}

.imagepagebtn {
  font-weight: 700 !important;
  margin-top: 30px !important;
  text-transform: none !important;
  margin-bottom: 40px !important;
}

.imagepagebtn svg {
  width: 17px;
  color: #0078d2;
}

.imagePageText {
  height: 68px;
  width: 490px;
  text-align: center;
  margin-top: 30px !important;
}
.locationTopGrid {
  display: grid;
  grid-template-columns: 15% 15% 35% 32%;
  align-items: start;
  column-gap: 2%;
  row-gap: 20px;
  margin-top: 20px;
}
.locationMainGrid {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  align-items: start;
  column-gap: 2%;
  row-gap: 20px;
  margin-top: 20px;
}
.map_links{
  display: block;
  width: 239px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 25px;
}
.createLocationheading1 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.createLocationheadin2 {
  font-size: 18px;
  font-weight: 500;
}

.createLocationheadin3 {
  font-size: 18px;
  font-weight: 500;
}

.createLocationformaLabel svg {
  color: #6b778c;
  width: 14px;
  height: 14px;
}

.DropZoneContainer {
  border: 1px dashed rgba(81, 111, 144, 0.4);
  height: fit-content;
}

.DropZone {
  min-height: 96px;
  height: auto;
  background: #f3f8fc;
  border-radius: 8px;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.DropzoneBtn {
  padding: 8px 22px !important;
  border-radius: 4px !important;
  width: 150px;
  margin-left: 47px !important;
}

.imageContainer {
  display: flex;
  column-gap: 2%;
  padding: 15px 20px;
  margin-bottom: 30px;
}

.subImageContainer {
  height: auto;
  width: 250px;
}

.imageText {
  display: flex;
  justify-content: space-between;
}

.mapandImageContainer {
  display: flex;
}

.createLocationbtnsectin {
  display: flex;
  justify-content: flex-end;
  column-gap: 25px;
  margin-top: 30px;
}

.createLocationbtnsectin button {
  text-transform: capitalize;
}

.locationsmainDiv {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
}

.teampageMaindiv {
  padding-top: 15px;
}

.teamMemberSearch > div {
  border-radius: 25px;
  background: white;
  padding: 3px 14px;
}

.TeamcardText {
  width: 62%;
}

.teamCard h5,
.teamCardContainer {
  padding: 10px !important;
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: relative;
  justify-content: space-evenly;
}

.filterbtns {
  display: flex !important;
  color: #565656 !important;
  border-color: #b5b5b5 !important;
  text-transform: capitalize !important;
  border-radius: 25px !important;
  min-width: 200px !important;
  justify-content: space-between !important;
  padding: 8px 20px !important;
  margin-bottom: 10px !important;
}

.modalmain svg {
  color: lightgray;
}

.locationsmainDiv svg {
  color: #0a1f41;
}

.viewstyleContainer {
  margin: 0 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewstyleText {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.viewbittonContainer {
  border-radius: 25px;
  margin-left: 20px;
  background: white;
  padding: 5px 10px;
  display: flex;
  column-gap: 10px;
  height: 40px;
}

.button {
  min-width: unset !important;
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
}

.button svg {
  color: #227dc3;
}

.active {
  background-color: #227dc3 !important;
}

.active svg {
  color: white;
}

.GridContainer {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  row-gap: 20px;
}

.locationGridCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UnderlineHr {
  color: #cdd3f8;
  margin: 10px 0 !important;
}

.memberCardText {
  width: 80%;
}

.teamCard {
  cursor: pointer;
  height: 290px;
}

.locationStatusChip span {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
}

.teamCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.locationCardButton {
  text-transform: none !important;
  padding: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.memberCardTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 15px;
}

.memberCardBtnSection button {
  padding: 6px 24px;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  letter-spacing: 0em;
}

.memberCardTextHeading {
  margin-top: 3px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  color: #0a1f41;
}

.memberCardText .memberCardTextHeading {
  margin-top: 0 !important;
}

.LinkEdit {
  cursor: pointer;
}

.LinkEdit svg {
  color: #1976d2;
}

.AudioRecorderBtn svg path {
  fill: #c8cbd1;
}

.detailsText1 {
  color: #0a1f41;
  font-weight: 500 !important;
  margin-top: 6px !important;
}

.detailsText2 {
  color: #0a1f41;
  font-weight: 500 !important;
  margin-bottom: 3px !important;
  font-size: 15px !important;
}

.DetailPageMainDiv {
  width: 99%;
  height: max-content;
  padding: 15px 20px 5px;
  margin-bottom: 20px;
}

.TextContainer1 {
  width: 85%;
  margin-top: 15px;
}

.customerDetails1 {
  display: flex;
  column-gap: 7%;
  margin-top: 15px;
}

.TextContainer2 {
  width: 50%;
  margin-top: 15px;
}

.detailspageimageContainer {
  width: 300px;
  height: 199px;
}

.audioBox {
  width: 260px;
  border: 1px solid lightgray;
  height: 20px;
  border-radius: 5px;
}

.TextContainer4 {
  column-gap: 15px;
}

.AssignMemberCard {
  height: 73px;
}

.assignMemberTeamText {
  font-family: Satoshi !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  color: #545476;
}

.TextContainer3 {
  margin: 15px 0 50px;
  column-gap: 20px;
}

.WaveformContianer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: transparent;
}

.PlayButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #efefef;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
}

.PlayButton :hover {
  background: #ddd;
}

.wave {
  width: 100%;
  height: 90px;
}

.addMembersuccess {
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 92%;
  justify-content: center;
  height: 90%;
  text-align: center;
  row-gap: 10px;
}

.addMembersuccess button {
  margin: 20px 0 10px;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #6b778c;
}

.imagePageText1 {
  margin-top: 10px !important;
  text-align: center;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  width: 84%;
  height: auto;
}

.AddMemberHeading {
  background: #dff1ff;
  padding: 23px 0;
}

.deleteConformationFirstDiv {
  height: 100px;
  margin: 30px;
}

.AddMemberHeading > p {
  width: 85%;
  margin: auto;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
}

.loginModalWidth {
  position: relative;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
  z-index: 1;
}

.depFormContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  row-gap: 10px;
  width: 90%;
  margin: 30px auto;
}

.addMemberModalbtnSection {
  display: flex;
  margin: 10px auto;
  justify-content: space-between;
  width: 100%;
}

.ReassignConfirmationModalHeading {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  color: #0a1f41;
}

.addMemberModalbtnSection button {
  width: 48%;
  padding: 11px 24px;
}

.AudioPlayerContainer {
  display: grid;
  grid-template-columns: 13% 67% 20%;
  align-items: center;
  height: 20px;
}

.waveForm {
  position: relative;
  height: 18px !important;
}

.waveForm > wave {
  height: 17px !important;
}

/* .waveForm > wave > wave {
  border-right: 0px !important;
  height: 12px;
  width: 12px !important;
  background: #4e5365;
  top: 37% !important;
  border-radius: 50%;
} */
.audioPlayer {
  display: grid;
  background-color: #f8f8fd;
  grid-template-columns: 88% 12%;
  width: 315px;
  margin-top: 5px !important;
  border-radius: 10px;
  align-items: center;
  padding: 5px;
}

.timePickerContainer {
  position: relative;
}

.timePicker > div {
  height: 48px;

}

.AudioPlayerBtn {
  min-width: 0 !important;
  width: 40px;
}
.timePicker  {
  cursor: pointer !important;
}
input[placeholder], [placeholder], *[placeholder]{
  cursor: pointer !important;
}
.audioTimerText {
  font-family: Satoshi !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  letter-spacing: 0.30000001192092896px !important;
  margin-left: 10px !important;
  color: #555569;
}

.selectMenuDepartment > div {
  display: flex;
  align-items: center;
}

.AddMemberDepMenuItem {
  text-transform: capitalize;
}

.filterHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 200px;
}

.claerBtn {
  font-size: 15px;
  font-weight: 600;
  color: blue;
  cursor: pointer;
}
.create_lo_add_from_map{
  padding: 0 !important;
  font-size: 14px !important;
  border: none;
  background-color: transparent;
  color:#3f73b5;
}
.snackBar {
  width: 100%;
  top: -380px !important;
  /* right: 12px !important; */
  left: 68% !important;
  right: 20px !important;
}

.snackBar svg {
  color: #5f2120 !important;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 15px;
  column-gap: 15px;
}

.brudCrumb svg {
  color: black;
}
.datePicker{
  cursor: pointer !important;
}
.datePickerCalendarIcon {
  right: 5px;
  position: absolute;
  top: 7px;
  cursor: text !important;
}
.datePickerCalendarIcon > svg{
  cursor: text !important;
}
.datePicker1 {
  cursor: pointer !important;
  position: relative;
  width: 250px;
}

.datePicker1 > div {
  width: 100%;
}

.datePicker1 > div > div {
  background-color: white !important;
  width: 100%;
}

.datePicker1 > div > div > input {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #bfd1cf;
  width: 100%;
  height: 48px;
  background-color: white;
}

.viewRoutContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.viewRouteText {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  color: #227dc3;
  cursor: pointer !important;
}

.viewRouteText svg {
  margin-right: 7px !important;
  width: 22px;
}

.LinkbtnViewGooleMap {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.LocationOnlineBadge span {
  min-width: 12px;
  height: 17px;
  border: 2px solid white;
  border-radius: 50%;
}

.filterHeader {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  letter-spacing: 0.25px;
  text-align: left;
}

.addressmap_container{
position: absolute;
width: auto;
padding: 10px;
bottom: 10px;
right:  20px;
background-color: white;
}