.SelectLanguage_maindiv {
  display: grid;
  grid-template-columns: 47% 53%;
  width: 100%;
  margin: auto;
  height: 100vh;
}

.commonbtn {
  font-size: 17px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  text-transform: capitalize !important;
  width: 100%;
  padding: 15px 24px !important;
  text-align: center;
}

.useOtpBtn {
  color: blue;
  font-size: 13px !important;
  cursor: pointer;
  text-transform: none !important;
}

.exeText {
  position: absolute !important;
  right: 0 !important;
  width: max-content !important;
}

.ModalHeadingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin: 10px auto;
}

.OtpSectionforgot {
  width: 100%;
}

.modalHeadind {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33.6px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #0a1f41 !important;
}

.modalHeadin2 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 130% !important;
  color: #6b778c !important;
  text-align: center !important;
}

.depFormContainer {
  margin: 20px 0;
}

.CheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forgetPasswordmodalclose {
  display: flex;
  justify-content: flex-end;
}

.forgetPasswordmodalclose svg {
  color: black;
  width: 25px;
  height: 25px;
}

.passwordInputContainer {
  position: relative;
}

.activeLAnguageBtn {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 23px !important;
  color: #0a1f41 !important;
  background: white !important;
}

.showPassword {
  position: absolute;
  top: 2px;
  right: 0px;
}

.InputForm svg {
  color: #6b778c;
}

.OtpFeildsContainer {
  justify-content: flex-start !important;
}

.OtpFeildsContainer > div {
  justify-content: space-between !important;
  width: 280px !important;
}

.OtpFeildsContainer input {
  margin: 0 !important;
  width: 55px !important;
}

.snackBar {
  top: -80% !important;
  /* right: 12px !important; */
  left: 76% !important;
}

.snackBar svg {
  color: #5f2120 !important;
}

.loginHeading {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 45px !important;
  color: #0a1f41;
}

.loginDescription {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  color: #6b778c;
  margin-top: 5px !important;
}

.login_form_lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}

.remember_input {
  font-weight: 600 !important;
  color: #0a1f41 !important;
}

.forgotbtn {
  color: #227dc3 !important;
}

.registerBtn {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #227dc3 !important;
}

.mobileNumber {
  color: #0a1f41;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.mobileNumber + svg {
  font-size: 24px;
}

.boldContainer {
  display: flex;
  column-gap: 10px;
  height: 25px;
  align-items: center;
  margin-bottom: 15px;
}

.nonActiveLanfuageBtn {
  font-family: Satoshi !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 23px !important;
  color: #6b778c;
}

.mobiunactive {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #6b778c;
}

.emailActive {
  color: #0a1f41 !important;
}

.wizardImage {
  height: auto;
  width: 100%;
  margin: 10px 0 15px;
}

.selectLangHeading {
  font-size: 27px !important;
}

.regSuccessImageContainer {
  background-color: transparent !important;
}

.regSuccessHeading {
  margin-top: 35px !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 31px !important;
  color: #0a1f41 !important;
}

.regSuccessDescription {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #2f2f61 !important;
  margin-bottom: 20px !important;
}

.videoModalBtn {
  cursor: pointer !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  color: #136fb2 !important;
}

.subsCardHeading {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  color: #0a1f41 !important;
}

.subsCardSubHeading {
  color: #0a1f41 !important;
  font-weight: 600 !important;
}

.durationsContainer {
  margin: 15px 0 10px !important;
}

.durationRadio {
  display: flex;
  align-items: center;
  column-gap: 5px !important;
  margin-bottom: 8px !important;
}

.durationRadio input {
  font-size: 14px !important;
  margin: 0 !important;
}

.durationRadio > div {
  height: 14px !important;
}

.secondSection {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.OtpSectionforgot .OtpFeildsContainer {
  justify-content: center !important;
}

.setPasswordForgetContainer {
  width: 95%;
}

.FeaturesList {
  display: flex;
  align-items: center;
  column-gap: 7px;
  height: 40px;
}

.forgotsubHeading {
  font-size: 14px !important;
}

.otpNumberUpdateBytn {
  text-transform: capitalize !important;
  margin-left: 10px !important;
  padding: 3px 16px !important;
}

.maindivForlogin {
  position: relative;
  display: grid;
  place-items: center;
  height: 100vh;
}

.InputForm {
  overflow-y: scroll;
}

.InputForm::-webkit-scrollbar {
  display: none;
}

.MaincntainerDiv {
}
