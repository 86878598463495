.teamMemberSearch > div {
  border-radius: 25px;
  height: 37.5px;
  background: white;
}

.ChatContainerMainDiv {
  display: grid;
  grid-template-columns: 35% 40% 25%;
}

.MessageInput {
  padding: 20px !important;
}

.chat_IndividualContactsMainDiv {
  display: flex;
  justify-content: space-between;
  /* min-height: 55px; */
}

.LocationOnlineBadge span {
  min-width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 50%;
}

.messageCount {
  position: absolute;
  top: 18px;
  right: 0px;
  border-radius: 75px;
  background: #349bfc;
  color: white;
  height: 20px;
  width: 20px;
  font-size: 12px !important;
  text-align: center;
  padding-top: 1px;
}

.ConatctList {
  overflow-y: scroll;
  max-height: calc(100vh - 215px);
  cursor: pointer;
}

.ConatctList::-webkit-scrollbar {
  display: none;
}

.ChatInput {
  position: relative;
  width: 100%;
  bottom: 0;
}

.chatSendBtnsContainer {
  display: flex;
  position: absolute;
  column-gap: 10px;
  top: 30px;
  right: 28px;
  align-items: center;
}

.contactListcontainer {
  /* background: #e1ffa37a; */
  padding: 15px;
  border-right: 1px solid #dadef2;
}

.Chat_messageMainCointainer {
  border-right: 1px solid #dadef2;
  position: relative;
}

.chatOtherImportantTexts {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #0a1f41;
}

.MedeasLinkContainerBox {
  height: 52px;
  background: #eef5fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.MedeasLinkContainerBox svg {
  margin-right: 0 !important;
}

.ContactSubDiv {
  display: grid;
  grid-template-columns: 50% 50%;
}

.chat_IndividualContactsSubDiv {
  display: flex;
  column-gap: 7px;
  align-items: center;
}

.chat_ContactPersonName {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 23px !important;
  color: #0a1f41;
}

.Chart_contactUnderLine {
  margin: 15px 0 !important;
}

.MessageBoxHEader {
  padding: 15px;
  border-bottom: 1px solid #dadef2;
  min-height: 79px;
}

.MessageBoxBody {
  height: calc(100vh - 90px);
  overflow-y: scroll;
}

.MessageBoxBody::-webkit-scrollbar {
  display: none;
}

.Chat_lastMessage {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #6b778c;
}

.CardMenu svg {
  color: black;
}

.CardMenu {
  min-width: 0 !important;
  padding: 0 !important;
}

.sentMessageContainer {
  display: flex;
  justify-content: flex-end;
}

/* .receivedMessageContainer {} */

.sentMessageContainer .Messagecontainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  column-gap: 5px;
  margin-right: 10px;
}

.receivedMessageContainer .Messagecontainer {
  display: flex;
  align-items: flex-end;
  column-gap: 5px;
  margin-left: 10px;
}

.Message {
  background: #eef5fb;
  width: max-content;
  padding: 3px 9px;
  border-radius: 7px;
  display: flex;
  column-gap: 5px;
  align-items: flex-end;
}

.seenIcon path {
  color: skyblue;
}

.unseenIcon path {
  color: lightgray;
}

.AudioPlayerContainer {
  display: grid;
  grid-template-columns: 13% 67% 20%;
  align-items: center;
  height: 40px;
  width: 320px !important;
}

.ChatInput aside {
  width: 100% !important;
}

.NameBars {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
}

.FileImage {
  padding: 5px !important;
  border-radius: 7px;
  width: 100%;
  max-height: 200px;
  max-width: 250px;
}

.HeaderSearchBarContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
