.header_flex {
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #f5f7fb;
}

.header {
  flex-shrink: 0;
}

.mainLayout {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.sidebar {
  flex-shrink: 0;
  width: 264px;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.smallSidebar {
  flex-shrink: 0;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.smallSidebar::-webkit-scrollbar {
  display: none;
}

.scrollableOutlet::-webkit-scrollbar {
  display: none;
}

.CalendarRangeContainer div > div {
  max-width: none !important;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;
}

.filterHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 200px;
}

.claerBtn {
  font-size: 15px;
  font-weight: 600;
  color: blue;
  cursor: pointer;
}

.content::-webkit-scrollbar {
  display: none;
}

.scrollableOutlet {
  flex-grow: 1;
  overflow-y: auto;
}

.header_maindiv {
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(179deg, #1273b8 16.03%, #b15265 126.55%);
  height: 70px;
  padding: 0 40px 0 10px;
}

.second_maindiv {
  align-items: center;
  column-gap: 20px;
}

.header_MenuIcon {
  column-gap: 20px;
  align-items: center !important;
}

.header_MenuIcon p,
svg {
  font-size: 18px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}


.second_maindiv p {
  color: white;
  cursor: pointer;
}
.EditMenuTeam{
  box-shadow: none !important;
}
.sideNaveBarli svg {
  color: unset;
  margin-right: 10px;
}

.MenuItems ul {
  width: 200px !important;
}

hr {
  margin: 4px 0 !important;
}

.nabBarContainer {
  /* display: grid !important;
    align-content: space-around !important; */
  width: 100%;
  padding-left: 0;
  background: white;
}

.nabBarContainer a,
li {
  height: 50px;
}

.nabBarContainer .sideNaveBarli {
  padding-left: 15px;
}

.activebtn {
  background: linear-gradient(
    90deg,
    rgba(19, 111, 178, 0.2) 0%,
    rgba(19, 111, 178, 0) 100%
  );
  display: block;
}

.activebtn .linkburron {
  color: #1273b8 !important;
  font-weight: 600 !important;
}

.activebtn .linkburron svg path {
  stroke: #1273b8 !important;
}

.linkburron {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #0a1f41 !important;
  margin-top: 8px !important;
  text-transform: none !important;
}

/* .mainLayout {
  display: grid;
  grid-template-columns: 19% 80%;
  column-gap: 1%;
} */
.RadioInput {
  display: inline-block;
}

.RadioInputContainer {
  display: flex;
  column-gap: 10px;
  width: 90%;
  margin: 10px auto;
}

.breadcrumMaindiv {
  display: flex;
  height: 60px;
  align-items: center;
  column-gap: 10px;
}

.breadcrumMaindiv li {
  height: unset;
}

.breadcrumMaindiv nav {
  margin-top: 0px !important;
}

.backicon {
  color: black;
  width: 35px;
  height: 35px;
}

.TableHead {
  display: flex !important;
  flex-direction: column;
}

.tableBody {
  display: flex !important;
  flex-direction: column;
  row-gap: 10px !important;
  margin: 10px 0;
}

.tableHeadRow {
  width: 100%;
  display: flex !important;
  align-items: center;
  background: #d7edff;
  border-radius: 10px !important;
}

.tableHeadCell {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  text-align: left;
  color: #0a1f41 !important;
  border: none !important;
}

.tableBodyRow {
  width: 100%;
  display: flex !important;
  align-items: center;
  border-radius: 10px !important;
  background: white;
}

.tableBodyCell {
  border: none !important;
}

.Tablecell1 {
  width: 20%;
  display: flex !important;
}

.Tablecell2 {
  width: 22%;
}

.Tablecell3 {
  width: 15%;
}

.Tablecell4 {
  width: 15%;
}

.Tablecell5 {
  width: 20%;
}

.Tablecell6 {
  width: 7%;
  text-align: right !important;
}

.Tablecell6 svg {
  color: black;
}

.HeaderUser_Name {
  font-family: Noto Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 13px;
  color: #ffffff;
  cursor: pointer;
}

.HeaderUser_designation {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

.subscription_tableCell {
  width: 25%;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  color: #0a1f41;
}

.HeaderSmallLogoImage {
  margin-left: 5px;
  margin-right: 10px;
}

.subscription_chip {
  padding: 14px 16px !important;
}

.subs_heading_cell {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #0a1f41;
}

.DownloadInvoiceBtn {
  font-family: Noto Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  color: #227dc3 !important;
  padding: 17.5px 16px !important;
  cursor: pointer !important;
}

.AddMemberHeading {
  position: relative;
  padding: 20px 20px 10px;
  background: #dff1ff;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
}

.modelInputsContainer {
  width: 90%;
  margin: 25px auto 10px;
  display: flex;
  height: 220px;
  flex-direction: column;
  justify-content: space-between;
}

/* .addMemberModalbtnSection {
  display: flex;
  margin: 10px auto;
  justify-content: space-between;
  width: 100%;
}

.addMemberModalbtnSection button {
  width: 48%;
} */

.inputs > div > div > span + div {
  display: none;
}

.menuItem {
  display: flex !important;
  justify-content: space-between !important;
}

.HeaderLogoImage {
  width:32px;
  height: 32px;
  
}
.HeaderLogo_div{
  display: flex;
  justify-content: center;
  margin-left: 14px;
  gap: 10px;
  align-items: center;
  margin-right: 105px;
}
.HeaderLogo_div >p{
  margin: 0px;
}
.AvatarDiv {
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}

.HeaderMenuItem {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #0a1f41 !important;
  display: flex !important;
  column-gap: 10px;
}

.HeaderMenuItem svg {
  width: 24px;
  height: 24px;
}

.CallIconHeader svg {
  width: 20px;
  margin-right: 4px;
}

.TooltipClasses {
  min-width: 420px !important;
  transform: translate(-210px, 26px) !important;
}

.TooltipClasses > div {
  max-width: unset !important;
  background: #fff !important;
  min-height: 380px !important;
  padding: 0 !important;
}

.tooltip_arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -3px;
  border-width: 13px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.TooltipHEader {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #0a1f41 !important;
  padding: 10px;
}

.NoImaheContainer {
  height: 345px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.HeaderNotificationButton:hover {
  border: none !important;
}

.resetPAsswordMenu {
  padding-left: 11px !important;
}

.resetPAsswordMenu svg {
  width: 27px;
  height: 33px;
}

.selectedMenu {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 23px !important;
  color: #0a1f41;
}

.deleteConformationFirstDiv {
  height: auto;
  margin: 30px;
}

.imagePageText {
  margin-top: 20px !important;
  text-align: center;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  width: 75%;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center;
  color: #6b778c;
}

.addMemberModalbtnSection {
  display: flex;
  margin: 25px auto 20px;
  justify-content: space-between;
  width: 100%;
}

.addMemberModalbtnSection button {
  width: 48%;
  padding: 11px 24px;
  text-transform: capitalize !important;
}

.snackBar {
  top: -80% !important;
  /* right: 12px !important; */
  left: 76% !important;
}

.snackBar svg {
  color: #5f2120 !important;
}

.NotificationItemMainDiv {
  position: relative;
  display: flex;
  padding: 8px 10px;
  column-gap: 10px;
  min-height: 85px;
}

.hover_button_container {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
}

.NotificationItemMainDiv:hover .hover_button_container {
  display: block;
}

.NotificationItemMainDiv:hover {
  background-color: #eaf6ff !important;
}

.notificationMessgeText {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c !important;
}

.notificationTime {
  position: absolute;
  right: 7px;
  bottom: 25px;
}

.TooltipmarkText {
  text-transform: none !important;
  font-size: 13px !important;
}

.unseencount {
  margin-left: 8px;
  background: #227dc3;
  padding: 2px 4px;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.viewAllbtnSection {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.notificationListContainer {
  height: 310px;
  overflow-y: scroll;
}

.NewBtn {
  position: absolute !important;
  right: 15px;
  top: 10px;
  width: 42px;
  height: 26px;
  min-width: 0 !important;
}

.notificationListContainer::-webkit-scrollbar {
  display: none;
}

.markAsReadBtn {
  position: absolute !important;
  right: 0px;
  bottom: 0px;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 15px;
  column-gap: 15px;
}

.brudCrumb svg {
  color: black;
}

.notificationHEader {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.notificationHeaderContainer {
  display: flex;
  padding: 20px;
}

.mesgadeTExt {
  font-weight: 400 !important;
  color: #6b778c;
}

.TimeTextNotifivcation {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}

.imageCoroselHeader {
  font-size: 30px !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  text-align: left;
  color: white;
}
