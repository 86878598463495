.MarkHolidayCalendarMain div div div div div {
  text-align: left !important;
  padding-left: 5px;
}

.rbc-selected > .rbc-day-bg > .rbc-day-content > button {
  /* Apply your custom styles here */
  background-color: lightblue;
  color: white;
  border: none;
  /* ... other styles */
}

.CalendarMainContainer {
  background: white;
  border: 1px solid #0000001f;
}

.fullCalendar a {
  color: #0a1f41 !important;
}

.fullCalendar td {
  height: 112px;
  width: calc(100% / 7);
}

.MuiDateCalendarContainer svg {
  color: black;
}

.CalendarHeader1 {
  display: flex;
  align-items: center;
  position: relative;
  background: #e9e9e9;
  height: 44px;
  padding: 10px;
  column-gap: 15px;
}

.NavigationButtons button {
  min-width: auto !important;
  padding: 0 !important;
}

.CalendarHeader2 {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.MarkerContainer {
  display: flex;
  column-gap: 10px;
  margin: 10px 0;
}

.MarkerDescriptionBar {
  width: 24px;
  height: 24px;
  background: #227dc3;
  border-radius: 4px;
}

.MarkerDescriptionText {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #0a1f41;
}

.HolidayPageBtnSection {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}

.MarkHolidayMainBtn {
  width: 178px;
  height: 48px;
  text-transform: none !important;
}

.CalenderSomText {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #0a1f41;
}

.mainDateText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #0a1f41;
}

.deleteConformationFirstDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.ConfirmationModalHeading {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  color: #0a1f41;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #6b778c;
}

.DateListItems {
  list-style: disc;
  height: auto;
}

.MarkAHolidaySubHeading {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: center;
  color: #0a1f41;
}

.TextInsideDateListItems {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #0a1f41;
}

.DateList {
  display: flex;
  column-gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.addMemberModalbtnSection {
  display: flex;
  margin-top: 20px;
  column-gap: 15px;
  width: 100%;
}

.addMemberModalbtnSection button {
  width: 48%;
  text-transform: none !important;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
}

.successImageContainer {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.succesImageText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #6b778c;
}
