.cardImpText {
  font-family: Noto Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  color: #0a1f41;
}

.infoWindowContainer svg {
  color: #0a1f41 !important;
  margin: 0 10px !important;
}

.cardLabelText {
  font-family: Noto Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  color: #6b778c;
}

.piechartHeading {
  font-family: Noto Sans !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
}

.linkBtntoAssign {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  cursor: pointer;
  color: #1273b8;
}

.infoWindowContainerHEader {
  display: flex;
  justify-content: space-between;
}

.nameContainer {
  width: 50%;
}

.infoWindowContainer {
  width: 350px;
}

.departmentCard {
  display: flex;
  align-items: center;
}

.infoSubHeader {
  width: 320px;
  margin: 10px 0;
  border-top: 1px solid #6b778c;
  padding: 15px 0;
  padding-right: 30px;
}

.piechartContainer {
  background-color: #f5fbff !important;
  padding: 10px;
}

.piechartSvg {
  width: 65px;
  height: 65px;
}

.piechartSubContainer {
  width: 70% !important;
}

.piechartHeading1 {
  margin: 10px 0px;
  align-items: center;
}

.mapcardContainer {
  position: absolute;
  bottom: 50px;
  right: 10px;
}

.mapcardContainer svg {
  color: black;
}

.UnderlineHr {
  color: #cdd3f8;
  margin: 10px 0 !important;
}

.CardContainer {
  padding: 16px !important;
  height: max-content !important;
  overflow-y: scroll !important;
  width: 350px !important;
  max-height: 410px;
}

.CardContainer::-webkit-scrollbar {
  display: none !important;
}

.CardHeaderContainer {
  padding: 0 !important;
}

.CardHeaderContainer > div > span {
  font-family: Noto Sans !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
}

.CardCollapeContainer {
  padding: 0 !important;
}

.CardContentContainer {
  padding: 0 !important;
}

.CardActionContainer {
  padding: 0 !important;
}

.CardActionContainer button {
  padding: 0 !important;
}

.CardContent1 {
  display: flex;
  justify-content: space-between;
}

.CollapseHeader {
  font-family: Satoshi !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #1d2022 !important;
}

.locationDeatilsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.snackBar {
  top: -410px !important;
  /* right: 12px !important; */
  left: 76% !important;
}

.snackBar svg {
  color: #5f2120 !important;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* margin-top: 15px; */
  column-gap: 15px;
}

.brudCrumb svg {
  color: black;
}

.MapFiltersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 33;
  width: 98%;
  margin: 0 1% 0;
  top: 15px;
}

.teamMemberSearch {
  width: 360px;
}

.teamMemberSearch > div {
  border-radius: 25px;
  background: white;
  padding: 3px 14px;
}

.filterbtns {
  display: flex !important;
  color: #565656 !important;
  border-color: #b5b5b5 !important;
  text-transform: capitalize !important;
  border-radius: 25px !important;
  min-width: 200px !important;
  justify-content: space-between !important;
  padding: 8px 20px !important;
}

.filterbtns svg {
  color: #0a1f41 !important;
  margin-right: 5px !important;
}

.filterHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 200px;
}

.claerBtn {
  font-size: 15px;
  font-weight: 600;
  color: blue;
  cursor: pointer;
}

.datePickerCalendarIcon {
  right: 5px;
  position: absolute;
  top: 7px;
}

.datePicker1 {
  position: relative;
  width: 250px;
}

.datePicker1 > div {
  width: 100%;
}

.datePicker1 > div > div {
  background-color: white !important;
}

.datePicker1 > div > div > input {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #bfd1cf;
  width: 100%;
  height: 48px;
  background-color: white;
}
