.CreateTeamindex {
  height: auto;
  min-height: 550px;
  background-color: #fff !important;
}

.CreateTeamimage {
  height: auto;
  width: auto;
  margin-top: 6%;
}

.indexpageMainBtn {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px;
}

.selectedTab {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.indexpageMainBtn button {
  text-transform: capitalize !important;
}

.indexpageMainBtn svg {
  width: 17px;
}

.imagePagemaindiv {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.imagePageText {
  margin-top: 20px !important;
  text-align: center;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  width: 70%;
}

.imagepagebtn {
  font-weight: 700 !important;
  margin-top: 30px !important;
  text-transform: capitalize !important;
  margin-bottom: 40px !important;
}

.imagepagebtn svg path {
  fill: #0078d2;
}

.imagepagebtn svg {
  width: 16px;
  margin-right: 7px;
}

.createTeamMaindiv {
  margin-top: 10px;
  width: 99%;
  padding: 15px 25px;
  margin-bottom: 15px;
}

.teamMembersmainDiv {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.svg_TotalNumber {
  font-size: 8px !important;
  font-weight: 500 !important;
  line-height: 31px !important;
  text-align: center;
  color: #0a1f41;
}

.svg_TotalText {
  font-size: 5px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: center;
}

.AddMemberFormMain {
  overflow-y: scroll;
  height: 150px;
}

.teamMembersmainDiv svg {
  color: black;
}

.teamMemberSearch > div {
  border-radius: 25px;
  height: 37.5px;
  background: white;
}

.addmemberImage {
  width: auto;
  height: auto;
  margin-top: 50px;
}

.CreateTeamSubmitSection {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}

.CreateTeamSubmitSection button {
  padding: 6px 45px;
  margin: 15px 0;
}

.noDataImagePAge svg path {
  fill: #fff;
}

.noDataImagePAge svg {
  width: 16px;
  margin-right: 7px;
}

.loginModalWidth {
  position: relative !important;
}

.AddMemberHeading {
  background: #dff1ff;
  padding: 23px 0;
}

.AddMemberHeading > p {
  width: 85%;
  margin: auto;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
}

.modelInputsContainer {
  width: 85%;
  margin: 25px auto 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
}

.addMemberModalbtnSection {
  display: flex;
  margin: 25px auto 20px;
  justify-content: space-between;
  width: 100%;
}

.addMemberModalbtnSection button {
  width: 48%;
  padding: 11px 24px;
  text-transform: capitalize !important;
}

.teamCard {
  padding: 0px !important;
  cursor: pointer;
}

.teamCardContainer {
  padding: 10px !important;
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: relative;
  justify-content: space-evenly;
  height: 80px;
}

.departmentCerdContainer {
  padding: 20px 10px !important;
  min-height: 60px;
}

.TeamcardText {
  width: 75%;
}
.CardMenuList{
  position: absolute;
  left: -160px !important;
  z-index: 1;
}
.form_lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}

.memberCardTextHeading {
  margin-top: 3px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  color: #0a1f41;
}

.locationStatusChip span {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
}

.locationCardButton {
  text-transform: none !important;
  padding: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.teamCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.memberCardText .memberCardTextHeading {
  margin-top: 0 !important;
}

.createTeamHeading {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.addDepartmentModal {
  margin: 15px auto 5px;
}

.choseOtherColoutTrxt {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #227dc3;
  cursor: pointer;
}

.TeamcardMainText {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
}

.GridContainer {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  row-gap: 20px;
  /* margin: 10px 3%; */
  padding: 20px 0px;
}

.TeamMemberGridcontaoiner {
  padding: 20px 0;
}

.CardMenu svg {
  color: black;
}

.CardMenu {
  min-width: 0 !important;
  padding: 0 !important;
}

.deleteIcon svg {
  color: black;
}

.TaskContainerheader {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 10px;
}

.taskCheckbox {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.taskCheckbox label {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #0a1f41;
}

.selectMenuDepartment > div {
  display: flex;
  align-items: center;
}

.addMembersuccess {
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 92%;
  justify-content: center;
  height: 90%;
  text-align: center;
  row-gap: 10px;
}

.addMembersuccess h4 {
  margin-top: 40px;
}

.membercardFooter {
  column-gap: 4%;
  display: grid;
  width: 100%;
  grid-template-columns: 48% 48%;
  margin: 19px 0 0;
}

.teamMembercardContainer {
  padding: 0px !important;
  justify-content: space-between;
  margin-bottom: 20px;
  height: auto !important;
}

.memberCardText {
  width: 70%;
  width: 10px 0;
}

.UnderlineHr {
  color: #cdd3f8;
  margin: 10px 0 !important;
}

.CardValues {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  margin-bottom: 0px !important;
}

.memberCardMenu {
  padding-left: 110px !important;
}

.teamPageHeading {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.teamPageHeading2 {
  margin-top: 5px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #0a1f41;
}

.TeamPageMaindivSubHeding {
  display: flex;
  align-items: center;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center;
  color: #6b778c;
}

.teamPageHeading2 svg {
  color: #0a1f41;
}

.teampageMaindiv {
  margin-top: 15px;
  padding-right: 15px;
}

.depFormContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  row-gap: 10px;
  width: 90%;
  margin: 20px auto;
}

.addDepartmentlable1 {
  color: #6b778c;
}

.departmentcaedText {
  display: flex;
  width: 45%;
}

.departmentcaedText svg {
  color: black;
  width: 15px;
  /* margin-bottom: -13px; */
  padding-bottom: 0px;
  margin-left: 5px;
}

.AddMemberDepMenuItem {
  text-transform: capitalize;
}

.cardMenuButton {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.memberTextsubContainer {
  width: 100%;
}

.TabPanel > div {
  padding: 24px 0;
  width: 100%;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 15px;
  column-gap: 15px;
}

.brudCrumb svg {
  color: black;
}

p > svg {
  margin-right: 15px !important;
}

.TeamMenuItem svg {
  width: 24px;
  height: 24px;
}

.EditMenuTeam svg {
  width: 25px;
  margin-right: 10px;
}

.TeamMemberViewPageIndexmaindiv svg {
  color: black;
}

.brudCrumb span {
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #0a1f41;
}

.brudCrumb span:hover {
  text-decoration: underline;
  color: blue;
}

.TabHeader {
  min-width: 150px !important;
}

.datePicker {
  position: relative;
}

.datePickerCalendarIcon {
  right: 5px;
  position: absolute;
  top: 10px;
}

.datePicker > div > div > input {
  padding: 10px;
  width: 170px;
}

.AddDepMainBtn {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  color: #227dc3 !important;
}

.AddDepMainBtn svg {
  color: #227dc3;
}

::placeholder {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #6b778c;
  /* color: red; */
}

.RepotsearchIcon svg {
  margin-right: 10px;
}

.RepotsearchIcon svg path {
  fill: white;
}

.TeamMemberPiechartBox {
  min-height: 200px;
  margin: 15px 0;
  padding: 15px;
}

.filterHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 200px;
}

.claerBtn {
  font-size: 15px;
  font-weight: 600;
  color: blue;
  cursor: pointer;
}

.filterbtns {
  display: flex !important;
  color: #565656 !important;
  border-color: #b5b5b5 !important;
  border-radius: 25px !important;
  min-width: 200px !important;
  justify-content: space-between !important;
  padding: 8px 20px !important;
  margin-bottom: 10px !important;
}

.sortingbtns {
  display: flex !important;
  color: #565656 !important;
  border-color: #b5b5b5 !important;
  border-radius: 25px !important;
  min-width: 269px !important;
  justify-content: space-between !important;
  padding: 8px 20px !important;
  margin-bottom: 10px !important;
}

.PieChartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}

.viewbittonContainer {
  border-radius: 25px;
  margin-left: 10px;
  background: white;
  padding: 5px 10px;
  display: flex;
  column-gap: 10px;
  width: max-content;
}

.button {
  min-width: unset !important;
  border-radius: 50% !important;
}

.button svg {
  color: #227dc3;
}

.active {
  background-color: #227dc3 !important;
}

.active svg {
  color: white;
}

.displayflexnrmlContainer {
  display: flex;
  align-items: center;
}

.displayFlexContainerSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.locationGridCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TeamMemberDetailsPage1 {
  padding: 20px;
  width: 100%;
}

.phoneIcom > svg {
  width: 17px;
}

.detailspageMaindiv {
  display: grid;
  row-gap: 25px;
}

.documentCard {
  display: flex;
  align-items: center;
  width: 32%;
  column-gap: 10px;
  padding: 10px 15px;
  justify-content: space-between;
}

.extensionImage {
  width: 18px;
  height: 23px;
}

.documentContainer {
  display: flex;
  column-gap: 2%;
  margin-top: 15px;
}

.documentName {
  width: 60%;
}

.ReportTextFieldsContainer {
  display: grid;
  grid-template-columns: 26% 26% 26% 16%;
  column-gap: 2%;
}

.datePicker1 {
  position: relative;
}

.datePicker1 > div {
  width: 100%;
}

.datePicker1 > div > div > input {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #bfd1cf;
  width: 100%;
}

.teamMemberBtn {
  width: 100%;
  margin-top: 25px !important;
  text-transform: capitalize !important;
}

.teamMemberBtn svg {
  width: 15px !important;
}

.textLable {
  color: #6b778c;
  margin-bottom: 10px !important;
}

.TabPanelReport > div {
  padding: 15px 0 24px;
}

.DownloadTypeBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.TeamMemberReport {
  margin-top: 20px !important;
  min-height: 550px;
}

.stepper {
  display: flex;
  flex-direction: column;
}

.route {
  width: 2px;
  background-color: #ccc;
  flex-grow: 1;
}

.route.completed {
  background-color: green;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
}

.TeamMemberReportHEadContaimner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step_number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #ccc;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepLableContainer {
  position: absolute;
  left: 40px;
  top: 2px;
}

.steppersMainContainer {
  margin: 0 0 25px;
}

.step_label {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0px !important;
  color: #0a1f41;
}

.sub_step_label {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: 0px !important;
  color: #6b778c;
}

.step.completed .step_number {
  background-color: green;
  border-color: green;
  color: #fff;
}

.RouteLine {
  width: 5px;
  height: 50px;
  background-color: #ccc;
  margin-left: 13px;
  margin-right: 16px;
}

.Completed {
  background-color: green;
}

.AttendanceContainer {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  row-gap: 20px;
  margin-top: 20px;
}

.viewstyleText {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #0a1f41;
}

.AttendanceCard {
  width: 90%;
  display: flex;
}

.attendanceCardName {
  padding: 10px;
  text-align: center;
  width: 30%;
  background: #e3f2fc;
}

.attendanceCardName2 {
  padding: 10px !important;
}

.attendanceCardName p {
  margin: 0 !important;
}

.snackBar {
  top: -410px !important;
  /* right: 12px !important; */
  left: 70% !important;
}

.snackBar svg {
  color: #5f2120 !important;
}

.createTeamImageText {
  width: 45% !important;
}

.TeamcardSubText {
  margin-top: 3px !important;
}

.deleteConformationFirstDiv {
  /* height: 140px; */
  margin: 30px;
}

.passwordInputContainer {
  position: relative;
}

.showPassword {
  position: absolute;
  top: 2px;
  right: 0px;
}

.InputForm {
  width: 85%;
  margin: 0 auto;
}

.TeamMemberViewPageIndexmaindiv {
  width: 100%;
  /* margin-left: 15px; */
}

.teamCardTextMain {
  margin-bottom: 10px !important;
}

.LocationOnlineBadge span {
  min-width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
}
