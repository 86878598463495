:root {
  --in-vertical-menu-bg: #fff;
  --in-vertical-menu-item-color: #0a1f41;
  --in-vertical-menu-item-hover-color: #227dc3;
  --in-vertical-menu-item-active-color: #227dc3;
  --in-vertical-menu-item-active-bgcolor: linear-gradient(to right,
      rgba(66, 138, 121, 0.1) 30%,
      transparent 100%);
  --in-vertical-menu-sub-item-color: #6c6f73;
  --in-vertical-menu-sub-item-hover-color: #227dc3;
  --in-vertical-menu-sub-item-active-color: #227dc3;
  --in-vertical-menu-title-color: #919da9;
  --in-vertical-menu-bg-dark: #227dc3;
  --in-vertical-menu-item-color-dark: #bfd1cf;
  --in-vertical-menu-item-hover-color-dark: #fff;
  --in-vertical-menu-item-active-color-dark: #fff;
  --in-vertical-menu-item-active-bgcolor-dark: linear-gradient(to right,
      rgba(255, 255, 255, 0.1) 30%,
      transparent 100%);
  --in-vertical-menu-sub-item-color-dark: #bfd1cf;
  --in-vertical-menu-sub-item-hover-color-dark: #fff;
  --in-vertical-menu-sub-item-active-color-dark: #fff;
  --in-vertical-menu-title-color-dark: #8cc0ba;
  --in-header-bg: #fff;
  --in-header-item-color: #e9ecef;
  --in-header-bg-dark: #227dc3;
  --in-header-item-color-dark: #bfd1cf;
  --in-topbar-search-bg: #f9f9f9;
  --in-topbar-user-bg: #f3f3f9;
  --in-topbar-user-bg-dark: #52639c;
  --in-footer-bg: #fff;
  --in-footer-color: #98a6ad;
  --in-topnav-bg: #fff;
  --in-topnav-item-color: #5d6064;
  --in-topnav-item-color-active: #227dc3;
  --in-boxed-body-bg: #ebebeb;
  --in-heading-color: #090914;
  --in-link-color: #227dc3;
  --in-link-hover-color: #227dc3;
  --in-border-color: #e9ebec;
  --in-card-bg-custom: #fff;
  --in-card-logo-dark: block;
  --in-card-logo-light: none;
  --in-list-group-hover-bg: #f5f5f5;
  --in-input-bg: #fff;
  --in-input-border: #ced4da;
  --in-input-focus-border: #a1c5bd;
  --in-input-disabled-bg: #eff2f7;
  --in-input-group-addon-bg: #eff2f7;
  --in-input-check-border: var(--in-input-border);
  --in-success: red;
}

* {
  list-style: none;
  outline: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--in-heading-color);
  font-family: "Noto Sans";
}

.fs-16 {
  font-size: 16px !important;
}

b,
strong {
  font-weight: 600;
}

a {
  text-decoration: none !important;
}

.bg-auth {
  position: relative;
  background-image: url(../images/Login.png) !important;
  background-position: center;
  height: 100%;
  width: 100% !important;
  background-size: cover;
  padding: 40px;
}

a,
button {
  outline: 0 !important;
  position: relative;
}

.fs-40 {
  font-size: 35px !important;
  line-height: 150%;
  font-family: "Noto Sans";
  font-style: normal;
}

.bg-auth .css-aq8pb7-MuiSvgIcon-root {
  font-size: 12px;
  color: white;
}

.bg-auth .css-1m9128y {
  color: white;
  position: absolute;
  top: 25%;
  display: flex;
  column-gap: 10px;
  left: 3%;
  text-align: unset;
  padding: 35px;
}

.TextInCorosel {
  color: white;
}

.textContainerImageCorosel {
  padding: 10px 15px;
}

.login-text {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #0a1f41;
  letter-spacing: 0px;
}

.login-text1 {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #6b778c;
}

#lang-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10000;
}

#lang-box a {
  display: block;
  background: #fff;
  background: #f5f7fb;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #6b778c;
  text-align: center;
  border: 5px solid #fff;
  padding: 17px;
}

.btn-primary {
  background-color: #227dc3;
  border-color: #227dc3 !important;
  padding: 10px 24px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.us {
  width: 66px;
  height: 66px;
  text-align: center;
  line-height: 38px;
  background: #dfe6ec;
  border-radius: 50px;
  padding: 10px;
}

.form-check .form-check-input {
  cursor: pointer;
}

.otp {
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
}

.otp input {
  margin: 0.7rem;
  padding: 13px 0;
  width: 70px;
  text-align: center;
  border: 1px solid #dfe1e6;
  font-weight: 600;
  border-radius: 5px;
}

input[placeholder],
[placeholder],
*[placeholder] {
  color: #6b778c;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
}

.login-text3 {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.login-text3 a {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
}

.fw-medium {
  font-weight: 500;
  margin-left: 5px;
}

.wrapper .header1 ul {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}

.wrapper .header1 ul li {
  margin-right: 50px;
  position: relative;
}

.wrapper .header1 ul li:last-child {
  margin-right: 0;
}

.wrapper .header1 ul li:before {
  content: "";
  position: absolute;
  top: 34%;
  transform: translateY(-50%);
  left: 93px;
  width: 167%;
  height: 2px;
}

.wrapper .header1 ul li:last-child:before {
  display: none;
}

.wrapper .header1 ul li div {
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wrapper .header1 ul li p {
  width: 24.5px;
  height: 24.5px;
  border: 2px solid #1273b8;
  color: #fff;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  margin: 0;
  font-size: 14px;
  background: linear-gradient(150.11deg,
      #1273b8 13.85%,
      rgba(197, 42, 56, 0.8) 100.26%);
}

.wrapper .header1 ul li .ule:before {
  font-family: remixicon !important;

  content: "\eb7b";
}

.wrapper .header1 ul li p:before {
  font-family: remixicon !important;
  color: #d9d9d9;
  content: "\eb7c";
}

.wrapper .header1 ul li.active:before {
  border: 1px dashed #227dc3;
}

.wrapper .header1 ul li.active p {
  border: 2px solid #1273b8;
  color: #1273b8;
  background: #fff;
}

.wrapper .header1 ul li.active p:before {
  font-family: remixicon !important;
  background: linear-gradient(150.11deg,
      #1273b8 13.85%,
      rgba(197, 42, 56, 0.8) 100.26%) !important;
  content: "\EB7B";
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text !important;
}

.wrapper .form_wrap {
  margin-bottom: 35px;
}

.wrapper .form_wrap h2 {
  color: var(--header-clr);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.wrapper .form_wrap .input_wrap {
  width: 350px;
  max-width: 100%;
  margin: 0 auto 20px;
}

.wrapper .form_wrap .input_wrap:last-child {
  margin-bottom: 0;
}

.wrapper .btns_wrap {
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.wrapper .btns_wrap .common_btns {
  display: flex;
  justify-content: space-between;
}

.wrapper .btns_wrap .common_btns.form_1_btns {
  justify-content: flex-end;
}

.wrapper .btns_wrap .common_btns button {
  border: 0;
  padding: 12px 15px;
  background: #227dc3;
  color: #fff;
  width: 135px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-radius: 3px;
  transition: 0.5s ease;
  cursor: pointer;
}

.wrapper .btns_wrap .common_btns button.btn_next .icon {
  display: flex;
  margin-left: 10px;
}

.wrapper .btns_wrap .common_btns button.btn_back .icon {
  display: flex;
  margin-right: 10px;
}

.wrapper .btns_wrap .common_btns button.btn_next:hover,
.wrapper .btns_wrap .common_btns button.btn_done:hover {
  background: #fff;
  border: 1px solid #227dc3;
  color: #000;
}

.wrapper .btns_wrap .common_btns button.btn_back:hover {
  background: var(--back-btn-hover);
}

.scrollbaroverflow {
  overflow-y: scroll;
}

.scrollbaroverflow::-webkit-scrollbar {
  display: none;
}

.login-text2 {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #227dc3;
}

.row>* {
  position: relative;
}

.card {
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
}

.bg-box {
  border-bottom: 4px solid #a042ff !important;
  background: #eeebfe !important;
  border-radius: 8px 8px 0px 0px;
}

.card-header {
  border-bottom: 1px solid var(--in-border-color);
}

.card-title {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 -1px 0;
  color: #0a1f41;
}

.bg-box1 {
  border-bottom: 4px solid #d80000 !important;
  background: #fceeee !important;
  border-radius: 8px 8px 0px 0px;
}

.bg-box2 {
  border-bottom: 4px solid #b08400 !important;
  background: #fffaeb !important;
  border-radius: 8px 8px 0px 0px;
}

.success {
  margin-top: 15%;
  text-align: center;
}