.chat-box .form-select {
    border: none !important;
}

.status.online {
    background: #4caf50;
}

.status.offline {
    background: #EF0606;
}

.status {
    width: 10px;
    height: 11px;
    left: 63px;
    top: 46px;
    border-radius: 5px;
    position: absolute;
}

.status1.online {
    background: #4caf50;
}

.status1.offline {
    background: #EF0606;
}

.status1 {
    width: 10px;
    height: 11px;
    left: 63px;
    top: 46px;
    border-radius: 5px;
    position: absolute;
}

li.user-list.active {
    background: #D3DEEA;
    border-radius: 10px;
    border-left: 10px solid #2D5173;
}

.chat-box .form-select {
    border: none !important;
}

.chat-list {


    height: auto;
    overflow: auto;
    scrollbar-width: none;
}

.chat-list1 {

    overflow: auto;
    scrollbar-width: none;
    max-height: calc(100vh - 296px);
}

.chat-mem-list {
    position: relative;
    display: block;
    color: #3B4B62;
    text-decoration: none;
}

.chat-list li {
    padding: 10px;
    margin-bottom: 20px;
}

.chat-list-item {
    position: relative;
    display: block;
    color: #3B4B62;
    text-decoration: none;
    margin-bottom: 8px;
}

.chat-message {
    display: flex;
    flex-direction: column;
    max-height: 800px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 5px;
}

.chat-message-left {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
}

.chat-message-right {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-end;
}

.chat-avatar {
    margin: 0 16px 0 16px;
}

/* .chat-message-left {
    margin-right: auto
} */

.chat-message-right {
    align-items: end;
    /* margin-left: auto */
}

.messages {

    overflow: auto;
    scrollbar-width: none;
    height: calc(109vh - 299px);
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.chat-list .badge {
    font-size: 10px;
}

.chat-box-height {
    height: auto;
    overflow: auto;
    scrollbar-width: none;
}

.chat-right {
    background: #D6E7F5;
    color: #000;

    padding: 11px;
    border-radius: 10px;
    width: 100%;
    font-size: 13px;
}

.chat-left.pdf-info {
    background: none !important;
    color: #3B4B62 !important;
    padding: 15px;
    border: 1px solid #3B4B62 !important;
    border-radius: 10px;
    width: 50%;
    font-size: 13px;
}

.chat-left.typing {
    background: none !important;
    color: #3B4B62 !important;
    padding: 5px;
    width: 100px !important;
    text-align: center;
    border: 1px solid #3B4B62 !important;
    border-radius: 10px;
    width: 50%;
    font-size: 20px;
}

div#wave {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

div#wave .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 3px;
    background: #303131;
    animation: wave 1.3s linear infinite;
}

div#wave .dot:nth-child(2) {
    animation-delay: -1.1s;
}

div#wave .dot:nth-child(3) {
    animation-delay: -0.9s;
}

@keyframes wave {

    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-15px);
    }
}

.chat-right.pdf-info {
    background: none !important;
    color: #26589F !important;
    padding: 15px;
    border: 1px solid #26589F !important;
    border-radius: 10px;
    width: 50%;
    font-size: 13px;
}

.chat-left {
    background: #D6E7F5;
    color: #000;
    padding: 11px;
    position: relative;
    border-radius: 10px;
    width: 100%;
    font-size: 13px;
}

.newest {
    margin-left: 220px;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

.input-icons img {
    padding: 0 6px;
    /* margin-right: 10px; */
}

.input-icons {
    position: absolute;
    left: 0;
    align-items: center;
    display: flex;
    height: 100%;
}

.input-button {
    position: absolute;
    right: 0;
    border: none;
    align-items: center;
    display: flex;
    height: 100%;
}

.input-button button:hover {
    background: none;
}

.input-button button {
    border: none;
}

.chat-input .form-control::placeholder {
    color: #686A70;
}

.chat-input .form-control {
    border-radius: 30px;
    height: 40px !important;
    padding: 10px 35px;
    background: #fff;
    color: #686A70;
    border: none;
}

.chat-content {
    background: #fff;
}

.file-icon {
    margin-top: 8px;
    width: 18px;
}

.page-item.show .page-link {
    background: #3b4b62;
    color: #fff;
    border: none;
}

.notify-content {
    display: flex;
}

.ticket-tab-form {
    background: #26589F;
    border-radius: 20px 20px 0 0;
}

.ticket-tab-form .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #26589F;
    border-bottom: 1px solid #fff !important;
    border-radius: 0;
}

.ticket-tab-form li {
    padding: 0 20px;
}

.ticket-form {
    /* padding: 0 120px; */
    padding-bottom: 20px;
}

.ticket-tab-form .nav-pills .nav-link {
    color: #fff;
}

.ticket-list {
    padding: 20px 50px;
}

.ticket-cancel {
    padding: 20px 50px;
}

.upload-file .form-control {
    height: auto !important;
}

.upload-file input[type=file]::file-selector-button {
    background-color: #F1F8FF !important;
    color: #3B4B62;
    border: 1px solid #767676;
    margin: 1px;
    transition: 1s;
}

.chat-input input[type=file]::-webkit-file-upload-button {
    display: none;
}

.chat-input .input-group {
    border: 1px solid #7676760a;
    margin: 10px;
    box-shadow: 0px 2px 12px 0px #2D4F5E21;
    border-radius: 10px;
}

.chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px
}

.chat-day-title .title {
    background-color: #fff;
    position: relative;
    font-size: 13px;
    z-index: 1;
    padding: 6px 12px;
    border-radius: 5px
}

.chat-day-title:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: #f0eff5;
    top: 10px
}

.chat-day-title .badge {
    font-size: 12px
}

/* .chat-input .input-group .btn{}  */
.bg-blue {
    color: #fff;
    background-color: #26589F;
}

.btn-plus {
    background-color: #84C529;
    color: #fff;
}

.ticket-list .table td {
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.ticket-list .table tr {
    border-bottom: #B6CAE2;
}

.btn-new {
    font-size: 12px;
    padding: 2px 10px;
    background: #26589F;
    color: #fff;
}



.page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff0;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.chat-list.nav-pills .nav-link.active {
    background: #d3deea36 !important;

    color: #3b4b62;


}

.chat-avatar img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
}

.chat-list.nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    max-height: calc(124vh - 296px);
}

@media only screen and (max-width:720px) {
    .menu-icon {
        display: block !important;
    }

    .inspire-img {
        height: 41px;
    }

    .hide-window {
        display: block !important;
    }

    .content-section .table td {
        font-size: 13px;
        padding: 15px 6px;
        color: #3B4B62 !important;
        vertical-align: middle;
    }

    .search-icon2 {
        position: absolute;
        top: 26px;
        left: 23px;
    }

    .btn-detail {
        background: #26589F;
        font-size: 12px;
        color: #fff;
        padding: 6px 5px;
        width: 97px;
    }

    .form-box .form-control,
    .form-select {
        font-size: 14px !important;

        color: #5E8EC8;
    }

    .content-section .table td {
        font-size: 12px;
        padding: 15px 0px;
        color: #3B4B62 !important;
        vertical-align: middle;
    }

    .menucenter {
        float: left;
    }

    .headind-tabl .col {
        width: 50% !important;
    }

    .main-content {
        width: 100%;
        max-width: 1200px;
        margin: auto;
        height: auto;
        display: flex;
        justify-content: center;
        border-radius: 25px;
        overflow: scroll;
        background: none !important;
        position: relative;
    }

    .main-content-das {
        width: 100%;
        max-width: 1300px;
        margin: 0px auto;
        height: auto;
        display: flex;
        justify-content: center;
        border-radius: 25px;
        overflow: scroll;
        background: none !important;
        object-fit: cover;
        position: relative;

    }

    .content-section {
        padding: 0px;
    }

    .navbar-nav.side-nav {
        display: none;
    }

    .navbar-nav.side-nav.opennav {
        display: flex !important;
        position: fixed;
        overflow: scroll;
        height: 100vh;
        left: 0;
        border-radius: 0px;
    }

    ul.navbar-nav.side-nav .active {

        justify-content: left;
    }

    .side-nav li {
        text-align: center !important;
    }

    .hide-mobile {
        display: none !important;
    }

    .add-content-section {
        padding: 0;
    }

    .follower-content-section {
        padding: 0;
    }

    .basic-details {
        padding: 0px;

    }

    .basic-details .table {
        margin: 0px;
    }

    .basci-bg {
        padding: 10px;
    }

    .package-box {
        height: auto;
    }

    .side-nav li small {
        display: inline-block;
        color: #fff;
        margin-left: 6px;
    }

    .nav-item .active .showweb {
        display: none;
    }

    .side-nav li {
        text-align: left !important;
    }

    .navbar-nav.side-nav {
        width: 150px;
    }

    .nav-item.active a {
        transform: none;
        margin: 0;
        margin: 0px 10px;

    }

    .nav-item.active svg {
        display: none;
    }

    .active .showmobile {
        display: block;
    }

    .side-nav li.active small {
        font-size: 10px;
        font-weight: 700;
        color: #3B4B62;
        text-transform: uppercase;
    }

    .side-nav li {
        margin: 5px 0px;
        text-align: center;
        line-height: 25px;
    }

    .nav-item.active {
        border-radius: 10px;
    }

    .nav-item.active b:nth-child(1) {
        display: none;
    }

    .nav-item.active b:nth-child(2) {
        display: none;
    }

    ul.navbar-nav.side-nav {
        padding-right: 10px;
    }

    .messgae-box {
        display: none;
    }

    .openchat {
        display: block !important;
    }

    .chat-box-height {
        height: 90vh;
    }

    .hidelist {
        display: none;
    }

    .chat-list {
        height: auto;

    }

    .messages {
        height: 522px;
        overflow: auto;
    }

    .back i {
        font-size: 20px;
        color: #3B4B62;
    }

    .hidemodeil {
        display: none;
    }

    .add-content-section {
        height: auto;
    }

    .notify-content {
        display: block;
    }

    .active-content img {
        text-align: center;
        margin-bottom: 10px;
    }

    .active-content .ps-3 {
        padding-left: 0 !important;
    }

    .notify-profile {
        text-align: center;
    }

    .add-ins-box .mb-4 {
        margin-bottom: 10px !important;
    }

    .add-ins-box {
        padding: 15px !important;
    }

    .ticket-form {
        padding: 20px;
    }

    .ticket-list {
        padding: 15px;
    }

    .ticket-cancel {
        padding: 10px;
    }

    .newest {
        margin-left: 0px !important;
    }

    .follower-content-section {
        padding: 10px !important;
    }

    .btn-follow {
        background: #3B4B62;
        color: #fff;
        padding: 5px 27px;
        font-size: 13px;
    }

    .user-box {

        justify-content: center;
    }

    .user-info {

        text-align: center;
    }
}

.title1 {


    margin-left: 33px;
}

@media only screen and (max-width:950px) {
    .add-ins-box {
        padding: 30px 30px;
    }

    .newest {
        margin-left: 50px;
    }

    .follower-content-section {
        padding: 50px;
    }

    .title1 {
        margin-left: 2px;
    }





    .menucenter {

        justify-content: center;
        padding: 10px;
    }

    .page-item.show .page-link {
        background: #3b4b62;
        color: #fff;
        border: none;
    }

    .add-ins-box .form-select {
        background-color: #F4F6F6;
        height: 35px;
        border: 1px solid #E1E2E3;
    }

    .newest {
        margin-left: 220px;
    }

    .page-link:hover {
        position: relative;
        display: block;
        color: #0d6efd;
        text-decoration: none;
        background-color: #3b4b62;
        border: 1px solid #dee2e6;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
}


/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}