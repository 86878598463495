.ContactusMaindiv {
  display: grid;
  grid-template-columns: 55% 45%;
  padding: 40px;
  margin: 20px auto;
}

.ImagePageTextContainr {
  display: grid;
  grid-template-columns: 55% 45%;
  row-gap: 20px;
}

.ContactusLogoContainer {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.CuntactUsHeading {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  color: #212145;
}

.LogosContainer {
  padding: 10px;
  background: #f5f5f6;
  border-radius: 10px;
}

.form_lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #545476;
}

.mainText {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  margin-top: 2px;
  color: #212145;
}

.feedbackEmojiImg {
  height: 74px;
  width: 306px;
}

.FeedbackBtnSection {
  display: flex;
  column-gap: 2%;
}

.FeedbackBtnSection button {
  width: 49%;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  border: 2px solid #1976d2;
}

.ContactusForm {
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  row-gap: 24px;
}

.browseFileTexr {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  color: #227dc3;
  margin-left: 7px;
}

.CuntactUsFormHeading {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #0a1f41;
}

.feedbackEmoji {
  opacity: 0.5;
}

.feedbackEmoji:hover {
  opacity: 1;
}

.contactformdropSection {
  text-align: center;
  border: 1px dashed #516f9066;
  background: #f3f8fc;
  padding: 15px 0;
  border-radius: 5px;
}

.contactdropText {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.brudCrumb {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.feedbackEmojiContainer {
  display: flex;
  column-gap: 7%;
}

.forgetPasswordmodalclose {
  display: flex;
  justify-content: flex-end;
}

.forgetPasswordmodalclose svg {
  color: black;
  width: 25px;
  height: 25px;
}

.ModalHeadingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin: 10px auto;
}

.modalHeadind {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33.6px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #0a1f41 !important;
}

.modalHeadin2 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 130% !important;
  color: #6b778c !important;
}

.ContactusFormButton {
  padding: 10px !important;
  text-transform: none !important;
}

.PaperContainerAboutus1 {
  display: flex;
  padding: 30px;
  column-gap: 40px;
  cursor: pointer;
}

.aboutus2image {
  width: 100%;
}

.fileText {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #545476;
}
