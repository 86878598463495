.DashboardCardMaindiv {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 15px;
}

.dashboardMainCardHeading {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  text-align: left;
  color: #0a1f41;
}

.subdiv1 {
  border-right: 1px solid #dadef2;
}

.subdiv2 {
  padding-left: 20px;
}

.svg_TotalNumber {
  font-size: 8px !important;
  font-weight: 500 !important;
  line-height: 31px !important;
  text-align: center;
  color: #0a1f41;
}

.svg_TotalText {
  font-size: 5px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: center;
}

.dashBoardPieCHartMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 35px;
}

.dashBoardPieCHartSubContainer {
  width: 150px;
  height: 150px;
}

.datePickerCalendarIcon {
  right: 5px;
  position: absolute;
  top: 8px;
}

.datePicker1 {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0px 0 15px;
}

.datePicker1 input {
  padding: 10px;
}

.snackBar {
  top: -80% !important;
  /* right: 12px !important; */
  left: 76% !important;
}

.snackBar svg {
  color: #5f2120 !important;
}

.holidayDashBoard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85vh;
  justify-content: center;
}

.holidayPageImageText {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  color: #0a1f41;
}
