.teamMemberSearch > div {
  border-radius: 25px;
  height: 37.5px;
  background: white;
}

.FaqHeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 20px;
}

.FaqHeadings {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #0a1f41;
}

.FaqViewAllButtons {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  color: #227dc3;
  cursor: pointer;
}

.AccordiansContainers {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.AccordianHeader {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  color: #0a1f41;
}

.AccordianContent {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #6b778c;
}

.AccordianTab {
  min-height: 65px;
}

.AccordianTab > div {
  min-height: 65px;
}

.AccordianTab svg {
  color: #000;
}

.faqButton {
  text-transform: none !important;
}
