.MyProfile_label {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b778c;
}

.myprofile_p {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a1f41;
  text-transform: capitalize;
  margin-left: 10px;
}

.email {
  text-transform: lowercase;
}

.my_profile_Container1 {
  align-items: center;
  column-gap: 75px;
}

.UnderlineHr {
  margin: 4px 0 20px !important;
}

.my_profile_Container1 svg {
  color: #0a1f41;
  margin-left: 10px;
}

.TabPanel > div {
  padding: 24px 0;
}

.CompanyLabel {
  margin-bottom: 5px;
}

.company_P {
  margin-left: 0;
}

.login_form_lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
  margin-bottom: 5px;
}

.compantTextContainer {
  row-gap: 20px;
  display: grid;
  grid-template-columns: 32% 32% 32% !important;
  column-gap: 2%;
}

.Comapny_headerContainer {
  display: flex;
  justify-content: space-between;
}

.LinkEdit {
  cursor: pointer;
}

.LinkEdit svg {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.LinkEdit svg path {
  fill: #1976d2;
}

.inputContainer {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  row-gap: 30px;
}

.InputForm svg {
  color: #6b778c;
}

.passwordInputContainer {
  position: relative;
}

.showPassword {
  position: absolute;
  top: 26px;
  right: 0;
}

.showPassword button {
  min-width: 0 !important;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 15px;
  column-gap: 15px;
}

.brudCrumb svg {
  color: #0a1f41;
}

.brudCrumb span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #0a1f41;
  cursor: pointer;
}

.loginModalWidth {
  position: relative !important;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
}

.deleteConformationFirstDiv {
  height: auto;
  margin: 30px;
}

.imagePageText {
  margin-top: 20px !important;
  text-align: center;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center;
  color: #6b778c;
}

.addMemberModalbtnSection {
  display: flex;
  margin: 25px auto 20px;
  justify-content: space-between;
  width: 100%;
}

.addMemberModalbtnSection button {
  width: 48%;
  padding: 11px 24px;
  text-transform: capitalize !important;
}

.ResetPasswordTitle {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.addMembersuccess {
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 92%;
  justify-content: center;
  height: 90%;
  text-align: center;
  row-gap: 10px;
}

.addMembersuccess h4 {
  margin-top: 40px;
}

.countryPinLable {
  position: absolute;
}
