.customDots {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  /* background-color: lightblue !important; */
  transition: background-color 0.3s ease !important;
  border: 1px solid #227dc3 !important;
  outline: none !important;
  cursor: pointer !important;
}

.slick-dots li {
  width: 10px !important;
}

li.slick-active {
  width: 24px !important;
}

.rbc-btn-group {
  display: none !important;
}

.slick-dots li.slick-active .customDots {
  background-color: #227dc3 !important;
  width: 24px !important;
  border-radius: 20px !important;
}

.css-1aquho2-MuiTabs-indicator {
  height: 4px !important;
}

.slick-dots {
  bottom: -30px !important;
}

.gm-style .gm-style-iw-tc::after {
  content: none !important;
}

.gm-style .gm-style-iw-c {
  top: 390px !important;
  left: 200px !important;
}

.gm-style .gm-style-iw-c .gm-ui-hover-effect {
  display: none !important;
}

.gmnoprint {
  display: none;
}

.gm-fullscreen-control {
  display: none;
}

div > div > a > div > img {
  display: none;
}

* {
  font-family: "Noto Sans" !important;
}

.form-check-input {
  border: 1px solid #0a1f41 !important;
}

.CalendarMain .rbc-month-row .rbc-date-cell {
  text-align: left !important;
  /* Align text to the right */
}

.fc-header-toolbar.fc-toolbar {
  display: none;
  /* Hide the element */
}

.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000de;
}

.fc-daygrid-day-top {
  display: inline-block !important;
}

.fc-scrollgrid-sync-inner {
  display: flex !important;
}

.fc-daygrid-day-top {
  flex-direction: row !important;
  padding: 5px;
  height: 35px;
}

.fc-daygrid-day-number {
  padding: 0 !important;
}

.nonModuleCss
  .MuiPickersArrowSwitcher-root.css-9reuh9-MuiPickersArrowSwitcher-root {
  display: none;
}

.nonModuleCss
  .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer.css-15v8kdh-MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer
  > div
  > div {
  display: none;
}

.nonModuleCss
  .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer.css-15v8kdh-MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer
  .MuiYearCalendar-root.css-1lkpf4l-MuiYearCalendar-root {
  display: grid;
  grid-template-columns: auto auto auto;
}

.nonModuleCss
  .MuiPickersCalendarHeader-root.css-nk89i7-MuiPickersCalendarHeader-root {
  margin: 0;
  padding: 0;
}

.nonModuleCss .MuiPickersCalendarHeader-root.css-1dozdou {
  margin: 0;
  padding: 0;
}

.nonModuleCss .MuiMonthCalendar-root.css-1k9sy1y {
  display: none;
}

.nonModuleCss
  .css-15v8kdh-MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer {
  position: absolute !important;
  z-index: 95;
  background: white;
  top: 40px;
}

.nonModuleCss
  .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer.css-1h73gvd {
  position: absolute !important;
  z-index: 95;
  background: white;
  top: 40px;
}

.nonModuleCss .MuiDateCalendar-root.css-cwhad8-MuiDateCalendar-root {
  width: 100% !important;
}

.nonModuleCss .MuiDateCalendar-root.css-1080di7 {
  width: 100% !important;
}

.nonModuleCss .selected_date_cell .fc-daygrid-day-number1 {
  background-color: #227dc3;
  color: white;
  line-height: 20px;
  padding: 3px;
  border-radius: 5px;
}

.fc-view-harness.fc-view-harness-active {
  height: 700px !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  height: auto !important;
  width: 100% !important;
}

.daterangepicker.ltr.show-ranges.opensright.show-calendar::before {
  right: 100px;
  left: unset;
}

.VeiwRouteMenubar
  > .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: 1px 1px 1px -8px rgba(0, 0, 0, 0.2),
    0px 1px 0px 1px rgb(247 247 247 / 0%), 0px 3px 4px 0px rgba(0, 0, 0, 0.01) !important;
}

.daterangepicker.ltr.show-ranges.opensright.show-calendar::after {
  right: 101px;
  left: unset;
}

.ranges li {
  height: max-content !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiPickersCalendarHeader-switchViewIcon.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
  color: black !important;
}
