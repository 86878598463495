.add_address{
    text-transform: capitalize !important;
    font-weight: 500 !important;
}
.add_header{
    background-color: #DFF1FF !important;
    padding: 16px !important;
    font-size: 22px !important;
    position: relative;
}
.address_close_icon{
  position: absolute;
  top: 5px;
  right: 10px;
}
.add_map_header{
    display: flex;
    gap: 8px;
    margin-bottom: 20px !important;
}
.add_map_header p:last-child{
    opacity: 0.7;
}
.add_location_box{
    background-color: #fff !important;
    border-radius: 6px !important;
    padding: 14px !important;
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.add_location_box span{
    display: block;
    opacity: 0.8;
    font-size: 15px !important;
    margin-bottom: 10px;
}
.add_location_box button{
    text-transform: capitalize !important;
    font-weight: 500 !important;
}
.viewstyleText {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #0a1f41;
}
.viewbittonContainer {
    border-radius: 25px;
    margin-left: 20px;
    background: white;
    padding: 5px 10px;
    display: flex;
    column-gap: 10px;
    height: 40px;
  }
.button {
    min-width: unset !important;
    border-radius: 50% !important;
    width: 32px;
    height: 32px;
  }
  
  .button svg {
    color: #227dc3;
  }
  
  .active {
    background-color: #227dc3 !important;
  }
  
  .active svg {
    color: white;
  }
  .teamMemberSearch > div {
    border-radius: 25px;
    background: white;
    padding: 3px 14px;
  }
  .grid_address{
    background-color: #fff !important;
    border-radius: 6px !important;
    padding: 12px !important;
    position: relative;
    height: 100%;
  }
  .address_part{
    display: flex;
    align-items: start;
    margin-bottom: 10px !important;
  }
  .address_part a{
        width: 243px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 27px;
  }
  .add_address_tit{
    margin-bottom: 6px !important;
    font-size: 16px !important;
  }
  .add_from_map{
    background-color: #fff !important;
    border: none !important;
    text-transform: capitalize !important;

  }
  .success_text{
    color:green !important;
    background-color:#0080001f !important;
    padding:6px 25px !important;
    margin-top: 7px !important;
    text-align: center !important;
  }
  .failure_text{
    color: red !important;
    background-color:#ff00002e !important;
    padding:6px 25px !important;
    margin-top:7px !important;
    text-align: center !important;
  }
  .del_address_btns{
    display: grid !important;
    grid-template-columns: auto auto !important;
    column-gap: 15px !important;
    margin-top: 12px !important;
  }
  .address_thead{
    background: #d7edff;
    border-radius: 10px !important;
  }
  .address_thead th{
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .address_tbody{
    background-color: #fff !important;
    border-radius: 10px !important;
  }
  .address_tbody td{
    font-size: 14px !important;
  }