.teamMemberSearch > div {
  border-radius: 25px;
  height: 37.5px;
  background: white;
}

.TabPanel > div {
  padding: 10px 0 0 !important;
}

.AvatarSection {
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 15px 0;
  position: relative;
}

.ModalHEaderMainText {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #0a1f41;
}

.ModalHEaderSubText {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #6b778c;
}

.ModalHeaderStatusText {
  position: absolute;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  color: #6b778c;
  right: 10px;
  top: 15px;
}

.CardContainer {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  row-gap: 20px;
}

.BlogsCardContent > img {
  width: 100%;
  height: 200px;
  object-fit: fill;
}

.blogChipCard {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  color: #0a1f41;
  background: #d7edff;
  padding: 5px;
  border-radius: 5px;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* margin-top: 15px; */
  column-gap: 15px;
}

.brudCrumb svg {
  color: black;
}

.brudCrumb span {
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #0a1f41;
}

.DetailsMainHEading {
  font-size: 40px !important;
  font-weight: 500 !important;
  line-height: 60px !important;
  color: #0a1f41;
  width: 72%;
}

.DetailsPAgeSubHEading {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  color: #0a1f41;
}

.Detailstext {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #6b778c;
}

.BloGPAgeSimilaRHEading {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  color: #0a1f41;
}

.BlogShareBtn {
  height: max-content;
}

.BlogShareBtn > button {
  height: 48px !important;
  width: 48px !important;
  background: #fff;
  box-shadow: none !important;
}

.BlogShareBtn > button:hover {
  background: #fff;
}
